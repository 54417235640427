import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, TextField,
  InputAdornment, Box, Container,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#b1a8a8'
  },
  container: {
    height: '75px'
  },
  bar: {
    height: '100%',
    position: 'relative'
  },
  text: {
    color: 'white',
    textShadow: '-3px 3px 2px rgba(0,0,0,0.25)',
  },
  chip: {
    marginLeft: '5px',
    color:'white',
    backgroundColor: "#bf1e2e",
    fontSize: "16px",
    padding: "6px 12px",
    borderRadius: "11px",
    marginLeft: "16px",
    position: 'absolute',
    top: '25px',
    textShadow: 'none',
  },
  searchBox: {
    backgroundColor: 'white', 
    borderRadius: '5%',
    '& input': {
      padding: theme.spacing(1.2)
    },
    '& svg': {
      color: '#b1a8a8'
    }
  }
}));

const  HeaderView = ({ Text, search, rowsCount, onChangeSearch }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container
        className={classes.container}>
        <Box
          className={classes.bar}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
            <Typography
              className={classes.text}
              variant='h4'>
              { Text }  
              {
                rowsCount !== undefined?
                  <span
                    className={classes.chip}>
                    { rowsCount.toString() }
                  </span>
                  : null
              }
            </Typography>
          {
            search?
              <TextField
                className={classes.searchBox}
                color="secondary"
                variant="outlined" 
                placeholder="Buscar por nombre"
                // fullWidth
                onChange={onChangeSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            : null
          }
        </Box>
      </Container>
    </div>
  );
}

export default HeaderView;