import React, { useState, useEffect } from 'react';
import { Container, Card, Typography, IconButton } from '@material-ui/core';
import HeaderView from '../../../components/HeaderView';
import GroupFormView from '../GroupFormView/GroupFormView';
import Alert from '../../../components/Alert';
import Table from '../../../components/Table/index';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import firebase from 'firebase/app';
import Services from '../../../services';

const useStyles = makeStyles(() => ({
    fontTitles : {
        fontSize: 25,
        color: '#757575'
    },
    icon: {
        color: '#a1a1a1',
    },
}));

const header = [
    {
        prop: 'name',
        name: 'Nombre',
    },
    {
        prop: 'email',
        name: 'Correo',
    },
    {
        prop: 'city',
        name: 'Ciudad'
    }
];

const getCodes = (codes) =>  {
    let arrayCodes = [];
    let code = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for(let index = 0; index < codes; index++) {
        for (let i = 0; i < 6; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        arrayCodes.push(code);
        code = '';
    }

    return arrayCodes;
}

const GroupCreateView = ({match, history}) =>  {
    useFirestoreConnect([{
        collection: "users", orderBy: "registry"
    }]);
    const usersSelector = useSelector(state => state.firestore.ordered.users);
    const classes = useStyles();
    const [group, setGroup] = useState({});
    const [error, setError ] = useState(false);
    const [success, setSuccess] = useState(false); 
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if(usersSelector && usersSelector.length) {
            setUsers(usersSelector)
        }
    }, [usersSelector]);
    
    if (!isLoaded(users)) {
        return <Loader />;
    }

    const handleChange = group => setGroup(group);
    
    const handleSubmit = async event => {
        try {
            event.preventDefault();

            const _group = {...group};
            _group.registry = firebase.firestore.Timestamp.now();
            _group.codes = _group.numberCodes ? getCodes(_group.numberCodes) : [];
            _group.users = users.filter(u => u.added).map(m => m.id);
            delete _group.id;

            const refGroup = await Services.addDocument("groups", _group);
            
            if(_group.users.length) {
                for (let i = 0; i < _group.users.length; i++) {
                    const user = users.find(u => u.id === _group.users[i]);
                    await Services.updateDocument("users", _group.users[i], {groups: [...user.groups, refGroup.id]})
                }
            }

            history.push("/groups/list/" + match.params.page);
        } catch (error) {
            console.log(error);
            setError(true);
        }
    }

    const handleClickAddUser = id => setUsers(users.map(u => ({...u, added: u.id === id ? true : u.added})));

    const handleClickDeleteUser = id =>  setUsers(users.map(u => ({...u, added: u.id === id ? false : u.added})));
  
    return (
        <div style={{ marginBottom: 100}}>
            <HeaderView Text='Agregar grupo' /> 
            <br />
            <Container>
                <Card>
                    <Container>
                        <GroupFormView 
                            group={group}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            submitLabel="Agregar"
                            onBack={() => history.push("/groups/list/" + match.params.page)}
                        />
                    </Container>    
                </Card>
                <br />
                <Card>
                    <Container>
                        <br />
                        <Typography className={classes.fontTitles}>AGREGAR USUARIOS</Typography>
                        <Table
                            paginated
                            header={header}
                            data={users}
                            filter={['name']}
                            extraRows={[
                                {
                                    prop: 'icon',
                                    name: 'Acción',
                                    cell: row => row.added
                                    ?
                                        <IconButton color="primary" aria-label="upload picture" 
                                            onClick={() => {handleClickDeleteUser(row.id)}} component="span"
                                        > 
                                            <DeleteIcon className={classes.icon}/> 
                                        </IconButton>
                                    :
                                        <IconButton color="primary" aria-label="upload picture" 
                                            onClick={() => {handleClickAddUser(row.id)}}
                                            component="span"
                                        > 
                                            <AddIcon className={classes.icon}/> 
                                        </IconButton>
                                }
                            ]}
                        />
                    </Container>
                </Card>
            </Container>
            <Alert
                open={error}
                onClose={() => setError(false)}
                message={'No se pudo crear el grupo.'} 
                severity="error"
            />
            <Alert
                open={success}
                onClose={() => setSuccess(false)}
                message={'El grupo se creó exitosamente.'} 
            />  
        </div>
    );
}

export default GroupCreateView;