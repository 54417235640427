import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Divider } from '@material-ui/core';

const GroupsDialog = ({ open, onAccept, groups }) => {
  return (
    <Dialog
      open={open}
     >
      <DialogTitle >Grupos</DialogTitle>
      <DialogContent>
      { 
        groups && groups.length > 0
        ?
          <div style={{ overflowY: 'auto', overflowX:'hidden'}}>
            {
              groups && groups.map((group, index) => (
                  <div  style={{margin:10}} key={index}>
                      <Typography>{group}</Typography>
                      <Divider />
                  </div>
              ))
            }
          </div>
        :
        <Typography>Sin grupos asignados</Typography> 
      }
      </DialogContent>
      <DialogActions>
          <Button onClick={onAccept} color="primary" autoFocus>
          Aceptar
          </Button>
      </DialogActions>
  </Dialog>
  );
};

export default GroupsDialog;