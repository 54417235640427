import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCv383wuzIqWKap2bq5avATVdOf-UciOFg",
  authDomain: "social-valid.firebaseapp.com",
  databaseURL: "https://social-valid.firebaseio.com",
  projectId: "social-valid",
  storageBucket: "social-valid.appspot.com",
  messagingSenderId: "126124613089",
  appId: "1:126124613089:web:27a19e9b32a0e853"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;