import asyncComponent from '../components/AsyncComponent';
import HomeView from './HomeView';
import LogInView from './LoginView/index';
import LogOutView from './LogOutView';
import UsersView from './users/UsersView';
import ProfileView from './profile/ProfileView';
import GroupsView from './groups/GroupsView';
import GroupCreateView from './groups/GroupCreateView/GroupCreateView';
import GroupEditView from './groups/GroupEditView/GroupEditView';
import EventsView from './events/EventsView';
import FormEventsView from './events/FormEventsView';
import ReportsView from './reports/ReportsView';
import LinksEventsView from './events/LinksEventsView';
import LinkEventView from './events/LinkEventView';
import UserEditView from './users/UserEditView';
import UserCreateView from './users/UserCreateView';
import NoticePrivacy from './noticePrivacy/NoticePrivacyView';
import WebView from './web/WebView';
import EventsEvidenceView from './events/EventsEvidenceView';
import EventsProgressView from './events/eventProgress/EventProgressView';

export { 
    HomeView, LogInView, LogOutView, UsersView, ProfileView, GroupsView, GroupCreateView, GroupEditView,
    EventsView, FormEventsView, ReportsView, LinksEventsView, LinkEventView, UserEditView, 
    UserCreateView, NoticePrivacy, WebView, EventsEvidenceView, EventsProgressView
};
