import { createMuiTheme } from '@material-ui/core/styles';
export default createMuiTheme({
    palette: {
      primary: {
        main: '#bf1e2e'
      },
      secondary: {
        main: '#D3D3D3'
      }
    },
    overrides: {
      MuiButton: {
        containedSecondary: {
          color: '#ffffff',
        },
      }
    }
});