import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../components/Loader';
import { useFirestore } from 'react-redux-firebase';
import {
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    Instagram as InstagramIcon,
    WhatsApp as WhatsAppIcon,
    YouTube as YouTubeIcon,
    Language as LanguageIcon
} from '@material-ui/icons';
import logo from '../../logo.png';
import TikTok from '../../tik-tok.png';
import sinImagen from '../../SinImagen.png';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import ViewHeader from './../../components/HeaderView';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'roboto',
    },
    button: {
        backgroundColor: '#ffc107', color: 'white', 
    },
    div: {
        textAlign: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    iconFacebook: {
        color: '#4267B2',
        height: 140,
        width: 140,
        padding: 1
    },
    iconTwitter: {
        color: '#00acee',
        height: 140,
        width: 140,
        padding: 1
    },
    iconInstagram: {
        color: '#E1306C',
        height: 140,
        width: 140,
        padding: 1
    },
    iconWhatsApp: {
        color: '#00bb2d',
        height: 140,
        width: 140,
        padding: 1
    },
    iconYouTube: {
        color: '#c4302b',
        height: 140,
        width: 140,
        padding: 1
    },
    iconInternet: {
        color: '#616161',
        height: 140,
        width: 140,
        padding: 1
    },
    fontTitle: {
        fontSize: 25,
        color: '#616161'
    },
    fontDescription: {
        fontSize: 35
    },
    mobileButton: {
        display: 'flex'
    },
    link: {
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        },
        textDecoration: 'none',
        fontFamily: 'roboto',
        color: '#575555',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
        transition: 'all 0.2s ease',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.07)'
        }
    },
    active: {
        backgroundColor: 'rgba(0,0,0,0.07)'
    },
    icon: {
        color: '#575555'
    },
    rootBox: {
        flexGrow: 1,
        textTransform: 'uppercase',
        backgroundColor: '#f6f6f6',
        position: 'relative',
        zIndex: '999',
        boxShadow: '-1px -3px 18px 6px rgba(0,0,0,0.12)',
        height: '80px',
        '& > *': {
          height: '100%'
        },
        '& img': {
          width: '150px'
        }
    },
    imageEvent: {
        height: 150,
        width: 150,
        borderRadius: '5%'
    },
    greenTitles: {
        color: '#87AA3B'
    }
}));

const LinkEventsView = ({ match }) => {
    const classes = useStyles();
    const firestore = useFirestore();
    const auth = useSelector(state => state.firebase.auth);
    const [eventData, setEventData] = useState();
    const [loaded, setLoaded] = useState(false);
    const [eventImageUrl, setEventImageUrl] = useState();
    const [actions, setActions] = useState('Sin acciones');
    
    useEffect(() => {
        //renombrar esta funcion no se que nombre ponerle...
        async function fetchData() {
            const event = await firestore.collection('events').doc(match.params.idEvent).get();
            
            setEventData(event.data());

            if(event.data().actions && event.data().actions.length > 0) {
                const actions = getActions(event.data().actions);
                
                setActions(actions);
            }

            if(event.data().imageUrl) {
                setEventImageUrl(event.data().imageUrl);
            }

            setLoaded(true);
            
            setTimeout(() => {
                const copyEventData = {... event.data()};
                
                if(match.params.idEvent != 'I9A5wFOTSoQgWzFeLP0i' && match.params.idEvent != 'QcbR8k4e9s74BSUiLqkw' && match.params.idEvent != 'a9IuS6NV6xxd94Sd2UHq' 
                    && !copyEventData.usersEvidence.includes(match.params.idUser) 
                    && Date.parse(copyEventData.dateInitial) <= Date.parse(moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'))
                ) {
                    copyEventData.usersEvidence.push(match.params.idUser);
                    firestore.collection('events').doc(match.params.idEvent)
                        .update({usersEvidence: copyEventData.usersEvidence})
                        .then(() => {
                            window.location = eventData.url;
                        });
                }
                else {
                    window.location = eventData.url;
                }
            }, 5000);
        }
        fetchData();
    },[loaded]);

    const getActions = actions => {
        let actionsString;

        for (let i = 0; i < actions.length; i++) {
            if(i > 0) {
                actionsString = actionsString + ', ' + actions[i];
            }
            else {
                actionsString = actions[i];
            }
        }

        return actionsString;
    }

    if(!loaded) { 
        return <Loader />;
    }       

    return (
        <div className={classes.root}>
        {
            eventData 
            ?
                <div className={classes.div}>
                    <Grid container spacing={2}>
                        {
                            isEmpty(auth)
                            ?
                                <Grid item xs={12} style={{padding: '0px'}}>
                                    <Box className={classes.rootBox} /* px={4}  */> 
                                        <Grid container >
                                            <Grid xs={1}
                                                className={classes.mobileButton}
                                                item
                                                container
                                                justify="center">
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Grid
                                                    container 
                                                    style={{ height:'100%' }} 
                                                    direction="row" 
                                                    justify="flex-start" 
                                                    alignItems="center">
                                                    <img src={logo}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            : 
                                null
                        }
                        <Grid item xs={12} style={{padding: '0px'}}>
                            <ViewHeader 
                                Text='Evento'
                                search={false}
                            />  
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography className={classes.fontTitle}>{eventData.name}</Typography>
                        </Grid>
                        <Grid item xs={12}><Divider variant="middle" style={{height:2}}/></Grid>
                        <Grid item xs={4} md={4}>
                            <Typography className={classes.greenTitles}><strong>PUBLICADO</strong></Typography>
                            <Typography>{eventData.dateInitial}</Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Typography className={classes.greenTitles}><strong>FINALIZA</strong></Typography>
                            <Typography>{eventData.dateFinal && eventData.dateFinal != '' ? eventData.dateFinal : 'Sin finalización'} </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Typography className={classes.greenTitles}><strong>ACCIÓN</strong></Typography>
                            <Typography>{actions}</Typography>
                        </Grid>
                        <Grid item xs={12}> <Divider variant="middle" style={{height:2}}/></Grid>
                        <Grid item xs={6} md={6}>
                            <img src={eventImageUrl ? eventImageUrl : sinImagen} className={classes.imageEvent} />
                            <Typography style={{marginTop: 10}}>{eventData.description}</Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Typography className={classes.greenTitles}><strong>RED SOCIAL</strong></Typography>
                            <Typography>{eventData.net}</Typography>
                            <br />
                            <a>
                            {
                                eventData.net == 'Facebook'
                                ?
                                    <FacebookIcon className={classes.iconFacebook} />
                                :
                                eventData.net == 'Twitter'
                                ?
                                    <TwitterIcon className={classes.iconTwitter} />
                                :
                                eventData.net == 'Instagram'
                                ?
                                    <InstagramIcon className={classes.iconInstagram} />
                                :
                                eventData.net == 'WhatsApp'
                                ?
                                    <WhatsAppIcon className={classes.iconWhatsApp} />
                                :
                                eventData.net == 'YouTube'
                                ?
                                    <YouTubeIcon className={classes.iconYouTube} />
                                :
                                eventData.net == 'TikTok'
                                ?
                                    <img src={TikTok} height="100"/>
                                :
                                    <LanguageIcon className={classes.iconInternet} />
                                
                            }
                            </a>
                        </Grid>
                    </Grid>
                    <br /><br /><br />
                </div>
            : 
                null
        }  
        </div>
    );
}
export default LinkEventsView;