import React, { useState, useEffect } from 'react';
import { Container, Card, Typography, IconButton, Button, Box} from '@material-ui/core';
import HeaderView from '../../../components/HeaderView';
import GroupFormView from '../GroupFormView/GroupFormView';
import Alert from '../../../components/Alert';
import Table from '../../../components/Table/index';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import Services from '../../../services';
import Loader from '../../../components/Loader';

const useStyles = makeStyles(() => ({
    fontTitles : {
        fontSize: 25,
        color: '#757575'
    },
    icon: {
        color: '#a1a1a1',
    },
    btn: {
        backgroundColor: '#bf1e2e', 
        color: 'white',
    },
}));

const header = [
    {
        prop: 'name',
        name: 'Nombre',
    },
    {
        prop: 'email',
        name: 'Correo',
    },
    {
        prop: 'city',
        name: 'Ciudad'
    }
];

const getCodes = (codes) =>  {
    let arrayCodes = [];
    let code = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for(let index = 0; index < codes; index++) {
        for (let i = 0; i < 6; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        arrayCodes.push(code);
        code = '';
    }

    return arrayCodes;
}

const GroupEditView = ({match, history}) =>  {
    useFirestoreConnect([{
        collection: "users", orderBy: "registry"
    }]);
    useFirestoreConnect([{
        collection: "events", orderBy: "registry", where: ["groups", "array-contains", match.params.id]
    }]);
    const usersSelector = useSelector(state => state.firestore.ordered.users);
    const groupEvents = useSelector(state => state.firestore.ordered.events);
    const classes = useStyles();
    const [group, setGroup] = useState({});
    const [error, setError ] = useState(false);
    const [success, setSuccess] = useState(false); 
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modeAddUser, setModeAddUser] = useState(false);
    const [groupUsers, setGroupUsers] = useState([]);

    useEffect(() => {
        const getData  = async () => {
            if(usersSelector && usersSelector.length && match.params.id) {
                try {
                    const groupDoc = await Services.getDocById("groups", match.params.id);

                    setGroup(groupDoc.data());
                    setGroupUsers(usersSelector.filter((u) => groupDoc.data().users.includes(u.id)));
                    setUsers(usersSelector.filter((u) => !groupDoc.data().users.includes(u.id)));

                } catch (e) {
                    console.log(e);
                } finally {
                    setLoading(false);
                }
            }
        }
        
        getData();

    }, [usersSelector, match]);

    if (!isLoaded(usersSelector) || !isLoaded(groupEvents) || loading) {
        return <Loader />;
    }
    
    const handleChange = group => setGroup(group);
    
    const handleSubmit = async event => {
        try {
            event.preventDefault();
            const _group = {...group};
            const newCodes = group.numberCodes ? getCodes(group.numberCodes) : [];
            _group.users = groupUsers.map(m => m.id);

            if(_group.users.length) {
                for (let i = 0; i < _group.users.length; i++) {
                    const user = usersSelector.find(u => u.id === _group.users[i]);

                    if(!user.groups.includes(match.params.id)) {
                        await Services.updateDocument("users", _group.users[i], {groups: [...user.groups, match.params.id]})
                    }
                }
            }

            if(users.length) {
                for (let i = 0; i < users.length; i++) {
                    if(users[i].groups.includes(match.params.id)) {
                        await Services.updateDocument("users", users[i].id, { groups: users[i].groups.filter(g => g !== match.params.id) })
                    }                    
                }
            }

            if(newCodes.length) {
                _group.codes = [..._group.codes, ...newCodes];
            }

            await Services.updateDocument("groups", match.params.id, _group);

            if(groupEvents.length) {
                const _groupEvents = [...groupEvents];
                for (let i = 0; i < _groupEvents.length; i++) {
                    if(!_groupEvents[i].dateFinal || _groupEvents[i].dateFinal >= moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')) {
                        for (let x = 0; x < _group.users.length; x++) {
                            if(!_groupEvents[i].users.includes(_group.users[x])) {
                                _groupEvents[i].users.push(_group.users[x]);
                            }
                        }   
                        await Services.updateDocument("events", groupEvents[i].id, {users:  _groupEvents[i].users});
                    }
                }
            }
        } catch (e) {
            console.log(e);
            setError(true);
        } finally {
            history.push("/groups/list/" + match.params.page);   
        }

    }
    
    const handleClickAddUser = user => {
        setGroupUsers([...groupUsers, user]);
        setUsers(users.filter(u => u.id !== user.id));
    }

    const handleClickDeleteUser = user =>  {
        setUsers([...users, user]);
        setGroupUsers(groupUsers.filter(g => g.id !== user.id));
    }

    const handleChangeMode = () => {
        setModeAddUser(!modeAddUser);
    }

    return (
        <div style={{ marginBottom: 100}}>
            <HeaderView Text='Editar grupo' /> 
            <br />
            <Container>
                <Card>
                    <Container>
                        <GroupFormView 
                            group={group}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            submitLabel="GUARDAR"
                            edit
                            groupEvents={groupEvents}
                            onBack={() => history.push("/groups/list/" + match.params.page)}
                        />
                    </Container>    
                </Card>
                <br />
                <Card>
                    <Container>
                        <br />
                        <div style={{ width: '100%' }}>
                            <Box display="flex" p={1}>
                                <Box p={1} flexGrow={1} >
                                    <Typography className={classes.fontTitles}>
                                    { modeAddUser ? 'USUARIOS FUERA DEL GRUPO' : 'USUARIOS DEL GRUPO' }
                                    </Typography>
                                </Box>
                                <Box p={1}>
                                    <Button variant="contained" className={classes.btn}
                                        type="button" onClick={handleChangeMode} fullWidth 
                                    >
                                    {  modeAddUser ? 'VER USUARIOS DEL GRUPO' : 'VER USUARIOS FUERA DEL GRUPO' }
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                        <Table
                            paginated
                            header={header}
                            data={modeAddUser ? users : groupUsers}
                            filter={['name']}
                            extraRows={[
                                {
                                    prop: 'code',
                                    name: 'Codigo',
                                    cell: row => 
                                        row.groups && row.groups.find(group => group.id == match.params.id)
                                            ? row.groups.find(group => group.id == match.params.id).code
                                            : 'Sin codigo'
                                },
                                {
                                    prop: 'icon',
                                    name: 'Acción',
                                    cell: row => 
                                        !modeAddUser
                                        ?
                                            <IconButton color="primary"
                                                onClick={() => {handleClickDeleteUser(row)}} component="span"
                                            > 
                                                <DeleteIcon className={classes.icon}/> 
                                            </IconButton>
                                        :
                                            <IconButton color="primary"
                                                onClick={() => {handleClickAddUser(row)}}
                                                component="span"
                                            > 
                                                <AddIcon className={classes.icon}/> 
                                            </IconButton>
                                }
                            ]}
                        />
                    </Container>
                </Card>
            </Container>
            <Alert
                open={error}
                onClose={() => setError(false)}
                message={'No se pudo crear el grupo.'} 
                severity="error"
            />
            <Alert
                open={success}
                onClose={() => setSuccess(false)}
                message={'El grupo se creó exitosamente.'} 
            />  
        </div>
    );
}

export default GroupEditView;