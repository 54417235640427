import React, { useState, useEffect, useRef, Fragment} from 'react';
import { Button, Container, Grid, Box, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Loader from './../../../components/Loader';
import ViewHeader from './../../../components/HeaderView';
import OrderableTable from './../../../components/Table/index';
import DeleteDialog from './DeleteDialog';
import GroupsDialog from './GroupsDialog';
import ActivityLevel from './ActivityLevel';
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Delete as DeleteIcon,
  Instagram as InstagramIcon,
  WhatsApp as WhatsAppIcon,
  YouTube as YouTubeIcon,
  Language as LanguageIcon,
  BarChart as BarChartIcon,
  Publish as PublishIcon,
  Edit as EditIcon,
  GetApp as GetAppIcon,
  Visibility as VisibilityIcon,
  Notifications as NotificationsIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import TableIconButton from './../../../components/TableIconButton';
import Alert from './../../../components/Alert';
import { CSVLink } from "react-csv";
import TikTok from '../../../tik-tok.png';
import Services from '../../../services';
import { useFirestoreConnect, isLoaded, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

const header = [
  {
    prop: 'name',
    name: 'Nombre'
  },
  {
    prop: 'dateFinal',
    name: 'Vencimiento'
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 50
  },
  delete: {
    cursor: 'pointer',
    color: '#a1a1a1'
  },
  button: {
    float: 'right',
    marginTop: theme.spacing(2)
  },
  exportLabel: {
    backgroundColor: '#4caf50',
    fontFamily: 'roboto',
    padding : 5,
    borderRadius: '5%',
    marginTop: '2%',
    color: 'white'
},
}));

const netToIcon = {
  Facebook: <FacebookIcon style={{ color: '#4267B2'}} />,
  Twitter: <TwitterIcon style={{ color: '#00acee'}} />,
  Instagram: <InstagramIcon style={{ color: '#E1306C'}} />,
  WhatsApp : <WhatsAppIcon style={{ color: '#00bb2d'}} />,
  YouTube : <YouTubeIcon style={{ color: '#c4302b'}} />,
  Internet : <LanguageIcon style={{ color: 'grey'}} />,
  TikTok : <img src={TikTok} height='20'/>
};


const getEventsWhitPorcentage = events => {
  let eventsWhitPercentage = [];

  events.forEach(event => {
    const usersEventsCount = event.users ? event.users.length : 0;
    const usersWhitEventCompleteCount = event.usersWhitEventComplete ? event.usersWhitEventComplete.length : 0;
    const eventCopy = {...event}
    
    let percentage = ((usersWhitEventCompleteCount / usersEventsCount) * 100).toFixed(2)
    
    if(isNaN(percentage)) { percentage = (0).toFixed(2) }
    
    eventCopy.percentage = percentage;
    eventCopy.downloadingCsv = false;
    eventCopy.sending = false;

    eventsWhitPercentage.push(eventCopy)
  })
  
  return eventsWhitPercentage;
}

const EventsView = ({match, history}) => {
  useFirestoreConnect([
    {
      collection: 'events',
      orderBy: ["dateInitial", "desc"]
    }, 
  ]);
  const eventsSelector = useSelector(state => state.firestore.ordered.events);
  const classes = useStyles();
  const firestore = useFirestore();
  const csvInstance = useRef();
  const [ events, setEvents ] = useState([]);
  const [ idEventToDelete, setIdEventToDelete ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ csvData, setCsvData ] = useState();
  const [ groupsNames, setGroupsNames ] = useState([]);
  const [ openGroupsDialog, setOpenGroupsDialog ] = useState(false);
  const [ openDialogSuccess, setOpenDialogSuccess ] = useState(false);
  const [ page, setPage ] = useState(parseInt(match.params.page) || 0);
  const [ alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [ csvData ]);
  
  useEffect(() => {
    if(eventsSelector && eventsSelector.length) {
      const eventsWhitPercentage = getEventsWhitPorcentage(eventsSelector);
      setEvents(eventsWhitPercentage);
      setLoading(false);
    }
  }, [eventsSelector]);

  if (loading || !isLoaded(eventsSelector)) {
    return <Loader />;
  }

  const handleDeleteSelect = id => {
    if(eventInvalid(id)) {
      setError('No se puede eliminar este evento.');
    }
    else {
      setIdEventToDelete(id);
    }
  }

  const handleDelete = () => {
    firestore
      .collection('events')
      .doc(idEventToDelete)
      .delete()
      .then(() => {
        setIdEventToDelete(null);
      });
  }

  const handleEditSelect = id => {
    if(eventInvalid(id)) {
      setError('No se puede editar este evento.');
    }
    else {
      history.push(`/events/formEvents/${ id }/${ page }`);
    }
  }

  const handleToEvidenceSelect = id => {
    if(eventInvalid(id)) {
      setError('No se puede hacer evidencia de este evento.');
    }
    else {
      history.push(`/events/evidence/${ id }/${ page }`);
    }
  }

  const handleToProgressSelect = id => {
    if(eventInvalid(id)) {
      setError('No se puede ver el avance de este evento.');
    }
    else {
      history.push(`/events/progress/${ id }/${ page }`);
    }
  }

  const handleDownloadReportSelect = async id => {
    if(eventInvalid(id)) {
      setError('No se puede descargar el reporte de este evento.');
      return;
    }

    const index = events.map(event => event.id).indexOf(id);
    const eventsCopy = [...events];
    eventsCopy[index].downloadingCsv = true;

    setEvents(eventsCopy);

    const eventDocument = await getDocumentById('events', id);
    let newCsvData = [];

    if(eventDocument.exists) {
      let eventUsers = [];

      newCsvData.push(['Evento: ', eventDocument.data().name]);
      newCsvData.push(['Fecha de inicio: ', eventDocument.data().dateInitial]);
      newCsvData.push(['Fecha fin: ', eventDocument.data().dateFinal ? eventDocument.data().dateFinal : 'Sin fecha fin']);
      newCsvData.push(['Tipo de evento: ', eventDocument.data().net]);
      
      newCsvData.push([]);
      newCsvData.push(['Nombre', 'Email', 'Cumplimiento', 'Evidencia', "Recibio evento (1 click)", "Entró evento (2 click)"]);

      if(eventDocument.data().users.length > 0) {
        for (let i = 0; i < eventDocument.data().users.length; i++) {
          const userDocument = await getDocumentById('users', eventDocument.data().users[i]);
          
          if(userDocument.exists) {
            eventUsers.push({id: userDocument.id, ...userDocument.data()});
          }
        }
      }
      
      const eventUsersOrdered = eventUsers.sort((a, b) => (a.name && a.name > b.name) ? 1 : -1);

      eventUsersOrdered.forEach(event => {
        newCsvData.push([
          event.name ? event.name : 'Sin nombre', 
          event.email ? event.email : 'Sin email',
          eventDocument.data().usersWhitEventComplete
            && eventDocument.data().usersWhitEventComplete.includes(event.id) ? 'Si' : 'No',
          eventDocument.data().usersEvidence
            && eventDocument.data().usersEvidence.includes(event.id) ? 'Si' : 'No',
          eventDocument.data().usersSawEvent
            && eventDocument.data().usersSawEvent.includes(event.id) ? 'Si' : 'No',
          eventDocument.data().usersSawEventWeb
            && eventDocument.data().usersSawEventWeb.includes(event.id) ? 'Si' : 'No'
        ]);
      });
    }

    eventsCopy[index].downloadingCsv = false;
    
    setEvents(eventsCopy);

    return newCsvData;
  }

  const getDocumentById = async (collection, id) => {
    return await firestore
    .collection(collection)
    .doc(id).get();
  }

  const eventInvalid = id => {
    return id == 'I9A5wFOTSoQgWzFeLP0i' || id == 'QcbR8k4e9s74BSUiLqkw'
  }

  const showGroups = async groupIds => {
    if(!groupIds || !groupIds.length) {
      setError('Este evento no grupos.');
      return;
    }

    let names = [];

    for (let i = 0; i < groupIds.length; i++) {
      const docGroup = await Services.getDocById("groups", groupIds[i]);
      names.push(docGroup.data().name);
    }

    setGroupsNames(names);
    setOpenGroupsDialog(true);
  }

  const handleSendNotifications = async (userIds, name, id) => {
    if(userIds.length < 1) {
      setError('Este evento no tiene usuarios.');
      return;
    }
    else {
      try  {
        setEvents(events.map(e => ({...e, sending: e.id === id ? true : e.sending})));

        await Services.post("send-notification", {
          userIds: userIds,
          name: name,
          id: id
        });

        setOpenDialogSuccess(true);
        setAlertMessage('Notificaciones envidas con exito!');
      } catch (e) {
        console.log(e);
        setError('Error al enviar notifiaciones')
      } finally {
        setEvents(events.map(e => ({...e, sending: e.id === id ? false : e.sending})));
      }
    }
  }

  const handleDeleteProgressSelect = id => {
    if(eventInvalid(id)) {
      setError('No se puede eliminar el avance de este evento.');
      return;
    }

    firestore.collection('events').doc(id).update({
      usersWhitEventComplete: []
    }).then(() => {
      setOpenDialogSuccess(true);
      setAlertMessage('Avance borrado con exito!');
    }).catch(() => setError('Error al eliminar el avance del evento.'));
  }

  return (
    <div className={classes.root}>
      <ViewHeader 
        Text='Eventos'
        rowsCount={events.length} 
      /> 
      <div style={{marginBottom: 90, marginRight: '2%', marginLeft: '2%'}}>
        <div style={{ width: '100%' }}>
          <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
            <Box>
              <Link to={`/events/formEvents/0/${page}`}>
                <Button
                  className={classes.button}
                  style={{backgroundColor: '#bf1e2e', color: 'white'}}
                  variant="contained">
                  Nuevo Evento
                </Button>
              </Link>
            </Box>
          </Box>
        </div>
        <div style={{ overflowY: 'hidden', overflowX:'auto'}}>
          <OrderableTable
            paginated
            filter={['name']}
            header={header}
            data={events}
            extraRows={[
              {
                prop: 'net-icon',
                name: 'Red',
                cell: row => netToIcon[row.net]
              },
              {
                prop: 'activity-level',
                name: 'Nivel de actividad',
                cell: row => <ActivityLevel level={row.percentage} />
              },
              {
                prop: 'groups-count',
                name: 'Grupos',
                cell: row => ( 
                  <Grid container>
                    <Grid item md={6} style={{marginTop: 2}}>
                      { row.groups && row.groups.length || 0 }
                    </Grid>
                    <Grid item md={6}>
                      <TableIconButton
                        icon={VisibilityIcon}
                        onClick={async () => await showGroups(row.groups)} />
                    </Grid>
                  </Grid>
                )
              },
              {
                prop: 'progress',
                name: 'Avance',
                cell: row => (
                  <TableIconButton
                    icon={BarChartIcon}
                    onClick={() => handleToProgressSelect(row.id)}/>
                )
              },
              {
                prop: 'evidence',
                name: 'Evidencia',
                cell: row => (
                  <TableIconButton
                    icon={PublishIcon}
                    onClick={() => handleToEvidenceSelect(row.id)}/>
                )
              },
              {
                prop: 'report',
                name: 'Reporte',
                cell: row => 
                  !row.downloadingCsv
                  ? 
                    <Fragment>
                      <div
                        onClick={async () => {
                          const newCsvData = await handleDownloadReportSelect(row.id);
                          setCsvData(newCsvData);
                        }}
                      >
                          <TableIconButton icon={GetAppIcon} />
                      </div>
                      {csvData ?
                        <CSVLink
                          filename={"ReporteEvento.csv"}
                          data={csvData}
                          ref={csvInstance}
                        />
                      : undefined }
                    </Fragment>
                  :
                    <Loader />
              },
              {
                prop: 'deleteProgress',
                name: 'Eliminar avance',
                cell: row => (
                  <TableIconButton
                    icon={DeleteIcon}
                    onClick={() => handleDeleteProgressSelect(row.id)}/>
                )
              },
              {
                prop: 'delete',
                name: 'Eliminar',
                cell: row => (
                  <TableIconButton
                    icon={DeleteIcon}
                    onClick={() => handleDeleteSelect(row.id)}/>
                )
              },
              {
                prop: 'extraEdit',
                name: 'Editar',
                cell: row => (
                  <TableIconButton
                    icon={EditIcon}
                    onClick={() => handleEditSelect(row.id)}/>
                )
              },
              {
                prop: 'noti',
                name: 'Notificación',
                cell: row => <>
                {
                  row.sending 
                  ?
                    <CircularProgress />
                  :
                    <TableIconButton
                      icon={NotificationsIcon}
                      onClick={async () => await handleSendNotifications(
                                      row.users ? row.users : [],
                                      row.name,
                                      row.id
                                    )
                      }
                    />
                }
                </>
              },
            ]}
            _page={page}
            onChangePage={page => setPage(page)}
          />
        </div> 
      </div>
      <DeleteDialog
        open={Boolean(idEventToDelete)}
        onClose={() => setIdEventToDelete(null)}
        onCancel={() => setIdEventToDelete(null)}
        onAccept={() => handleDelete()}
      />
      <GroupsDialog
        open={openGroupsDialog}
        onAccept={() => setOpenGroupsDialog(false) }
        groups={groupsNames}
      />
      <Alert
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity="error" />
      <Alert
        open={openDialogSuccess}
        onClose={() => setOpenDialogSuccess(false)}
        message={alertMessage}
        severity="success" 
      />
    </div>
  );
};

export default EventsView;