import React, {useState, useEffect}from 'react';
import Container from './../components/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from 'react-router-dom';
import Loader from './../components/Loader';
import { useFirestore } from 'react-redux-firebase';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
  starIcon: {
    color: '#ffc107', 
    padding: 30,
  },
  root: {
    fontFamily: 'roboto',
    paddingTop: theme.spacing(10),
    paddingRight: '30px',
    paddingLeft: '30px', 
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0'
    }
  },
  top: {
    backgroundImage: 'linear-gradient(#33691e, #8bc34a)'
  },
  buttonTop: {
    color: 'white',
    backgroundColor: 'black',
    marginBottom : -15
  },
  iconsBody: {
    color: 'white',
    backgroundColor: '#bf1e2e',
    fontSize: '60px',
    borderRadius: '50px',
    padding: '10px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.4)',
    transition: 'all 0.2s ease',
    marginBottom: theme.spacing(1.2),
    '&:hover': {
      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.42)',
      transform: 'scale(0.97)'
    }
  },
  buttonBody: {
    marginTop: 20, 
    transition: 'all 0.2s ease',
    fontSize: '14px',
    margin: theme.spacing(0, 1),
    textTransform: 'none',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
    '&:hover': {
      boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)'
    }
  },
  buttonsBody1: {
    backgroundColor: '#aed581',
    '&:hover': {
      backgroundColor: '#a0c37a'
    }
  },
  buttonsBody2: {
    backgroundColor: '#ef9a9a',
    '&:hover': {
      backgroundColor: '#e88e8e'
    }
  },
  buttonsBody3: {
    backgroundColor: '#fff176',
    '&:hover': {
      backgroundColor: '#f7e96e'
    }
  }
}));

export default function HomeView (props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const [loaded, setLoaded] = useState(false);
  const [eventsActiveCount, setEventsActiveCount] = useState(0);
  const [eventsInactiveCount, setEventsInactiveCount] = useState(0);
  const [usersActiveCount, setUsersActiveCount] = useState(0);
  const [groupsActiveCount, setGroupsActiveCount] = useState(0);

  useEffect(() => {
      async function getHomeData() {
        const events = await firestore.collection('events').get();
        const users = await firestore.collection('users').get();
        const groups = await firestore.collection('groups').get();
        const eventsArray = snapToArray(events.docs);  
        const eventsActiveAndInactiveCount = getEventsActiveAndInactiveCount(eventsArray);

        setUsersActiveCount(users.docs.length);
        setGroupsActiveCount(groups.docs.length);
        setEventsActiveCount(eventsActiveAndInactiveCount.activeCount);
        setEventsInactiveCount(eventsActiveAndInactiveCount.inctiveCunt);
        setLoaded(true);
      }
      
      getHomeData();
    
  }, [loaded]);

  const snapToArray = docs => {
    return docs
      .map(doc => ({ 
        id: doc.id,
        ...doc.data()
      }));
  }
  
  const getEventsActiveAndInactiveCount = events => {
    let activeCount = 0;
    let inctiveCunt = 0;
   
    events.forEach(event => {
      if( event.dateFinal == '' || (event.dateFinal != '' && Date.parse(event.dateFinal) > Date.parse(moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'))) ) {
        activeCount++;
      }
      else {
        inctiveCunt++;
      }
    });

    return {
      activeCount: activeCount,
      inctiveCunt: inctiveCunt
    };
  }
  if (!loaded) {
    return <Loader />;
  }
  return (
    <Container>
      <div className={classes.root}>
        <Grid container spacing={3} className={classes.body}>
          <Grid align='center' item xs={12} sm={6} md={3}>
            <Link to="/events/list/0">
              <EventAvailableIcon
                className={classes.iconsBody}/>
              <Typography
                variant="h5">
                EVENTOS
              </Typography>
            </Link>
            <Grid container justify="center">
              <Button
                variant='contained' 
                disableElevation
                className={`${classes.buttonBody} ${classes.buttonsBody1}`}>
                  {eventsActiveCount} Activos
              </Button> 
              <Button 
                variant='contained' 
                disableElevation
                className={`${classes.buttonBody} ${classes.buttonsBody2}`}>
                  {eventsInactiveCount} Finalizados
              </Button>
            </Grid>
          </Grid>
          <Grid align='center' item xs={12} sm={6} md={3}>
            <Link to="/users/list/0">
              <PersonIcon
                className={classes.iconsBody}/>
              <Typography
                variant="h5">
                USUARIOS
              </Typography>
            </Link>
            <Grid container justify="center">
              <Button 
                disableElevation
                variant='contained' 
                className={`${classes.buttonBody} ${classes.buttonsBody1}`}>
                  {usersActiveCount} Activos
              </Button> 
            </Grid>
          </Grid>
          <Grid align='center' item xs={12} sm={6} md={3}>
            <Link to="/groups/list/0">
              <PeopleIcon
                className={classes.iconsBody}/>
              <Typography
                variant="h5">
                GRUPOS
              </Typography>
            </Link>
            <Grid container justify="center" spacing={1}>
              <Button 
                variant='contained' 
                disableElevation
                className={`${classes.buttonBody} ${classes.buttonsBody1}`}>
                  {groupsActiveCount} Activos
              </Button>
            </Grid>
          </Grid>
          <Grid align='center' item xs={12} sm={6} md={3}>
            <Link to="/reports">
              <AssignmentIcon
                className={classes.iconsBody}/>
              <Typography
                variant="h5">
                REPORTES
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};