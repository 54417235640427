import React, { useState, useEffect } from 'react';
import HeaderView from '../../../components/HeaderView';
import { Container, Grid, Card, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFirestore } from 'react-redux-firebase';
import Loader from '../../../components/Loader';
import { Bar } from 'react-chartjs-2';
import EventDetails from './EventProgreesDetailView';
import Pies from './PiesView';
import EvidencesDialog from './EvidencesDialogView';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        paddingBottom: '100px'
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    starIcon: {
        color: '#ffc107', 
        paddingRight: 0,
    },
    fontTitles: {
        color: '#757575'
    },
    card: {
        marginBottom: 120
    },
    gridList: {
        height: 200,
    },
    btn: {
        backgroundColor: '#bf1e2e', 
        color: 'white'
    },
}));
    
const EventsProgressView = ({ match, history }) => {
    const classes = useStyles();
    const firestore = useFirestore();
    const [eventData, setEventData] = useState();
    const [loaded, setLoaded] = useState(false);
    const [barData, setBarData] = useState();
    const [piesData, setPiesData] = useState([]);
    const [theyFulfilled, setTheyFulfilled] = useState(0);
    const [pending, setPending] = useState(0);
    const [level, setLevel] = useState(0);
    const [openEvidencesDialog, setOpenEvidencesDialog] = useState(false);
    const [userEvidences, setUserEvidences] = useState([]);

    useEffect(() => {
        if (!loaded) {
            firestore.collection('events').doc(match.params.id).get().then(async (eventDocument) => {
                const usersWhitEventCompleteCount = eventDocument.data().usersWhitEventComplete 
                    ? eventDocument.data().usersWhitEventComplete.length : 0;
                const _pending = getPending(
                    eventDocument.data().users ? eventDocument.data().users.length : 0, 
                    usersWhitEventCompleteCount
                );
                let _level = getLevel(usersWhitEventCompleteCount, _pending);
                const _barDataAndPiesData = await getBarDataAndPiesData(
                    eventDocument.data().groups ? eventDocument.data().groups : [], 
                    eventDocument.data().users ? eventDocument.data().users : [], 
                    eventDocument.data().usersWhitEventComplete ? eventDocument.data().usersWhitEventComplete : [], 
                );

                if(isNaN(_level)) { _level = 0}
                setEventData(eventDocument.data());
                setLevel(_level.toFixed(2));
                setPending(_pending);
                setTheyFulfilled(usersWhitEventCompleteCount);
                setBarData(_barDataAndPiesData.barData);
                setPiesData(_barDataAndPiesData.piesData);
                setLoaded(true);
            });
        }
    }, [loaded]);

    const getPending = (usersEventCount, usersWhitEventCompleteCount) =>  
        usersEventCount - usersWhitEventCompleteCount; 
    
    const getLevel = (usersWhitEventCompleteCount, usersPendingCount) => 
       (usersWhitEventCompleteCount / (usersWhitEventCompleteCount + usersPendingCount)) * 100;

    const getBarDataAndPiesData = async (groupIds, eventUserIds, usersWhitEventComplete) => {
        let barData = {
            labels: [],
            datasets: [
                {
                    label: 'Cumplimiento por grupo',
                    data: [],
                    backgroundColor: [
                        '#87AA3B',
                    ]
                }
            ],
            groupUsers: []
        }

        if(groupIds.length > 0) {
            const groups = await getGroupsEvent(groupIds);
           
            if(groups.length > 0) {
                let groupUserIds = [];
                let groupPercentages = [];

                //meter esto en un metodo mucho codigo
                for (let i = 0; i < groups.length; i++) {
                    const groupUsers = await getGroupUsers(groups[i].users ? groups[i].users : []);
                    const groupPercentage = getGroupPercentages(
                        groups[i].users ? groups[i].users  : [], 
                        usersWhitEventComplete
                    );

                    barData.groupUsers.push(groupUsers);
                    barData.labels.push(groups[i].name);
                    barData.datasets[0].backgroundColor.push('#87AA3B');
                    groupPercentages.push(groupPercentage); 

                    if(groups[i].users && groups[i].users.length > 0) {
                        groups[i].users.forEach(userId => {
                            if(!groupUserIds.includes(userId)) {
                                groupUserIds.push(userId);
                            }
                        });
                    }  
                }

                const userWhitOutGroupIds = getUserWhitOutGroupIds(groupUserIds, eventUserIds);

                if(userWhitOutGroupIds.length > 0) {
                    const percentageUsersWhitOutGroup = getPercentageUsersWhitOutGroup(userWhitOutGroupIds, usersWhitEventComplete);
                    const groupUsers = await getGroupUsers(userWhitOutGroupIds);
                    
                    barData.groupUsers.push(groupUsers);
                    groupPercentages.push(percentageUsersWhitOutGroup);
                    barData.labels.push('Usuarios sin grupo');
                    barData.datasets[0].backgroundColor.push('#87AA3B');
                }

                barData.datasets[0].data = groupPercentages;
            }
        }

        const piesData = getPiesData(barData);

        return {
            barData: barData,
            piesData: piesData
        };
    }

    const getGroupsEvent = async groupIds => {
        let groups = [];

        for (let i = 0; i < groupIds.length; i++) {
            const group = await firestore.collection('groups').doc(groupIds[i]).get();
                
            if(group.exists) {
                groups.push({id: group.id, ...group.data()});
            }
        }

        return groups;
    }

    const getUserWhitOutGroupIds = (groupUserIds, eventUserIds) => {
        let getUserIdsWhitOutGroup = [];

        eventUserIds.forEach(eventUserId => {
            if(!groupUserIds.includes(eventUserId)) {
                getUserIdsWhitOutGroup.push(eventUserId);
            }
        });

        return getUserIdsWhitOutGroup; 
    }

    const getGroupPercentages = (groupUserIds, usersWhitEventComplete) => {
        let groupPercentage = 0;

        if(usersWhitEventComplete.length > 0) {
            let groupUserIdsCount = groupUserIds.length;
            let usersWhitEventCompleteCount = 0;

            if(groupUserIds.length > 0)
            
            groupUserIds.forEach(groupUserId => {
                if(usersWhitEventComplete.includes(groupUserId)) {
                    usersWhitEventCompleteCount ++;
                }
            });

            groupPercentage = (usersWhitEventCompleteCount / groupUserIdsCount) * 100;
        }
       
        return groupPercentage < 1 ? null : groupPercentage.toFixed(2);
    }

    const getPercentageUsersWhitOutGroup = (userWhitOutGroupIds, usersWhitEventComplete) => {
        let percentage = 0;
        
        if(usersWhitEventComplete.length > 0) {
            let usersWhitEventCompleteCount = 0;

            userWhitOutGroupIds.forEach(userWhitOutGroupId => {
                if(usersWhitEventComplete.includes(userWhitOutGroupId)) {
                    usersWhitEventCompleteCount++;
                }
            });

            percentage = (usersWhitEventCompleteCount / userWhitOutGroupIds.length) * 100;
        }
       
        return percentage < 1 ? null : percentage.toFixed(2);
    }

    const getPiesData = barData => {
        let piesData = [];
        
        if(barData.labels.length > 0) {
            for (let i = 0; i < barData.labels.length; i++) {
                piesData.push({
                    labels: ['Personas que cumplieron','Personas que no cumplieron'],
                    datasets: [
                        {
                            label: '',
                            data: [
                                barData.datasets[0].data[i],
                                100 - barData.datasets[0].data[i]
                            ],
                            backgroundColor: ['#87AA3B','#f44336']
                        },
                    ],
                    groupName: barData.labels[i],
                    groupUsers: barData.groupUsers[i]
                });
            }
        }
       
        return piesData;
    }

    const getGroupUsers = async userIds => {
        let groupUsers = []; 

        if(userIds.length > 0) {
            for (let i = 0; i < userIds.length; i++) {
                const user = await firestore.collection('users').doc(userIds[i]).get();
                
                if(user.exists) {
                    groupUsers.push({id: user.id, ...user.data()})
                }
            }
        }

        return groupUsers;
    }

    const openModalEvidences = evidences => {
        if(evidences.length) {
            setUserEvidences(evidences);
            setOpenEvidencesDialog(true);
        }
    }
   
    if (!loaded) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <HeaderView Text={'Reporte'} />
            <Container style={{marginTop: 30}}>
                <div style={{marginBottom: 10}}>
                    <Button variant="contained" className={classes.btn} 
                        onClick={() => history.push('/events/list/'+ match.params.page)}
                    >
                        Regresar
                    </Button>
                </div>
                <Grid container spacing={1}>
                    <EventDetails event={eventData} theyFulfilled={theyFulfilled} pending={pending} 
                        level={level} 
                    />
                    <Grid item xs={12} md={12} style={{marginBottom: 20, marginTop: 15}}>
                        <Card>
                            <Bar data={barData} options={{maintainAspectRatio: false}}  
                                height={200}
                            />
                            <br />
                        </Card> 
                    </Grid>
                    <Pies piesData={piesData} 
                        usersWhitEventComplete={eventData.usersWhitEventComplete}
                        usersEvidence={eventData.usersEvidence}
                        usersSawEvent={eventData.usersSawEvent || []}
                        usersSawEventWeb={eventData.usersSawEventWeb || []}
                        eventId={match.params.id}
                        openModalEvidences={(evidences) => openModalEvidences(evidences)}
                    />
                </Grid>   
            </Container>
            <EvidencesDialog 
                open={openEvidencesDialog}
                onAccept={() => setOpenEvidencesDialog(false) }
                evidences={userEvidences} 
            />
        </div>
    );
}

export default EventsProgressView;