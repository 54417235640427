import React, { useState, useEffect } from 'react';
import HeaderView from '../../components/HeaderView';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Container, Grid, TextField, Button, Typography, Divider, 
    InputAdornment, FormControl, Select, MenuItem, InputLabel, Card,
    Table, TableCell, TableBody, TableRow, TableHead, IconButton, 
    TablePagination, Checkbox, Box, Input, CircularProgress} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment-timezone';
import Loader from '../../components/Loader';
import { isLoaded, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import LanguageIcon from '@material-ui/icons/Language';
import sinImagen from '../../SinImagen.png';
import Alert from './../../components/Alert';
import ActivityLevel from './EventsView/ActivityLevel';
import firebase from 'firebase/app';
import Services from '../../services';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EventUsersDialog from './EventUsersDialogView';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        marginBottom: 50
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    starIcon: {
        color: '#ffc107', 
        paddingRight: 0,
    },
    fontTitles: {
        color: '#757575'
    },
    fontSubTitles : {
        fontSize: 25
    },
    btnUpdate: {
        backgroundColor: '#bf1e2e', 
        color: 'white'
    },
    marginBar: {
        marginTop: 10
    },
    card: {
        marginBottom: 120
    },
    gridList: {
        height: 200,
    },
    imageEvent: {
        height: 170,
        width: 170,
        borderRadius: '5%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
}));

const actionsNames = [
    'Ver',
    'Me gusta',
    'No me gusta',
    'Compartir',
    'Comentario positivo',
    'Comentario negativo',
];

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

const FormEventsView = ({ match, history }) => {
    useFirestoreConnect([
        {
          collection: 'groups',
          orderBy: ["registry", "desc"]
        }, 
    ]);
    useFirestoreConnect([
        {
          collection: 'events',
          doc: match.params.id
        }
    ]);
    const groups = useSelector(state => state.firestore.ordered.groups);
    const eventSelector = useSelector(({ firestore: { data } }) => data.events && data.events[match.params.id]);
    const classes = useStyles();
    const firestore = useFirestore();
    const [dataEvent, setDataEvent] = useState(
        match.params.id != 0 ? null 
        : { id : 0, net: 'Facebook',
            dateInitial: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'),
            dateFinal: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')
    });
    const [dataNewEvents, setDataNewEvents] = useState([{url: '', net: 'Facebook'}]);
    const [modeAdd, setModeAdd] = useState(false);
    const [page, setPage ] = useState(0);
    const [rowsPerPage, setRowsPerPage ] = useState(5);
    const [query, setQuery] = useState("");
    const [errors, setErrors] = useState([]);
    const [withoutDateFinal, setWitoutDateFinal] = useState(false);
    const [imageEvent, setImageEvent] = useState();
    const [imageUrlEvent, setImageUrlEvent] = useState('');
    const [error, setError ] = useState(null);
    const [loading, setLoading] = useState(false);
    const [eventLevel, setEventLevel] = useState(0);
    const [dataGroups, setDataGroups] = useState([]);
    const [loading2, setLoading2] = useState(false);
    const [openEventUsersDialog, setOpenEventUsersDialog] = useState(false);
    const [usersEvent, setUsersEvent] = useState([]);

    useEffect(() => {
        if(groups && groups.length) {
            if(match.params.id != 0 && eventSelector) {
                try {
                    setLoading2(true);
                    const usersWhitEventCompleteCount = eventSelector.usersWhitEventComplete.length;
                    let _eventLevel = ((usersWhitEventCompleteCount / eventSelector.users.length) * 100).toFixed(2) 
                    
                    if(isNaN(_eventLevel)) {_eventLevel = 0} 
               
                    if(eventSelector.imageUrl) {
                        setImageUrlEvent(eventSelector.imageUrl);
                    }

                    setDataEvent(eventSelector);
                    setEventLevel(_eventLevel);
                    setDataGroups(groups.filter(g => eventSelector.groups.includes(g.id)));
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoading2(false);
                }
            } 
            else {
                setDataGroups(groups.map(g => ({...g, added: false})));
            }
        }
       
    }, [match, groups, eventSelector]);

    if(loading2 || !isLoaded(groups) || !isLoaded(eventSelector)) { 
        return <Loader />;
    }
    //#region Commands

    const onChange = (prop, value) => setDataEvent({...dataEvent, [prop]: value});

    const addOrUpdate = async () => {
        let errors = [];
        
        if(!dataEvent.name) {
            errors.push("name");
        }
        if(!dataEvent.city) {
            errors.push("city");
        }
        if(!dataEvent.description) {
            errors.push("description");
        }
        if(match.params.id == 0 && dataNewEvents.some(d => !d.url)) {
            errors.push("urls");
        }

        setErrors(errors);

        if(errors.length) return;

        try {
            setLoading(true);

            const _dataEvent = {...dataEvent};

            if(match.params.id == 0) {
                delete dataEvent.id;
                dataEvent.evidencePaths = [];
                dataEvent.usersEvidence = [];
                dataEvent.usersWhitEventComplete = [];
                dataEvent.level = 0;
                dataEvent.registry = firebase.firestore.Timestamp.now(); 
                
                if(withoutDateFinal) {
                    dataEvent.dateFinal = "";
                }

                let arrayUsers = [];
    
                for(let index = 0; index < dataGroups.length; index++) {
                    if(dataGroups[index].added) {
                        arrayUsers = [...arrayUsers, ...dataGroups[index].users];
                    }              
                }
    
                dataEvent.users = arrayUsers;
                dataEvent.groups = dataGroups.filter(d => d.added).map(m => m.id);

                if(imageEvent) {
                    const dateNow = new Date().toLocaleString();
                    const path = "ImagenesEventos/" + dateNow.replaceAll("/", "-") + "-" + imageEvent.name;
                    const url = await Services.uploadFile(path, imageEvent);
    
                    if(url !== "error") {
                        dataEvent.imageUrl = url;
                        dataEvent.imagePath = path;
                    }
                }
    
                for(let index = 0; index < dataNewEvents.length; index++) {
                    dataEvent.url = dataNewEvents[index].url;
                    dataEvent.net = dataNewEvents[index].net;
                    await Services.addDocument("events", dataEvent);
                }
            }
            else {
                let arrayUsers = [];
                let newGroups = [];

                for(let x = 0; x < dataEvent.groups.length; x++) {
                    for(let i = 0; i < groups.length; i++) {
                        if(groups[i].id == dataEvent.groups[x]) {
                            newGroups.push(groups[i]);
                        }
                    }
                }

                for(let index = 0; index < newGroups.length; index++) {
                    for(let i = 0; i < newGroups[index].users.length; i++) {
                        if(!arrayUsers.includes(newGroups[index].users[i])) {
                            arrayUsers.push(newGroups[index].users[i]);
                        }
                    }
                }

                _dataEvent.users = arrayUsers;

                if(imageEvent) {
                    if(_dataEvent.imagePath) {
                        await firebase.storage().ref().child(_dataEvent.imagePath).delete();
                    }
                    const dateNow = new Date().toLocaleString();
                    const path = "ImagenesEventos/" + dateNow.replaceAll("/", "-") + "-" + imageEvent.name;
                    
                    const url = await Services.uploadFile(path, imageEvent);
    
                    if(url !== "error") {
                        _dataEvent.imageUrl = url;
                        _dataEvent.imagePath = path;
                    }
                }

                await firestore.collection('events').doc(match.params.id).update(_dataEvent)
            }
        } catch (error) {
            console.log(error);
        } finally {
            history.push('/events/list/'+ match.params.page);
            setLoading(false);
        }
    }

    const handleClickGetGroupsNoAddedOrAdded = () => {
        setPage(0);

        if(dataEvent.dateFinal == '' || dataEvent.dateFinal > moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')) {
            if(!modeAdd) {
                setDataGroups(groups.filter(g => !dataEvent.groups.includes(g.id)))
                setModeAdd(true);
            } 
            else {
                setDataGroups(groups.filter(g => dataEvent.groups.includes(g.id)))
                setModeAdd(false);
            }
        }
        else {
            setError('No se puede editar los grupos de un evento finalizado.');
        }
    }

    const handleClickDeleteGroupAdded = id => {
        const dataEvent2 = {...dataEvent}
        const dataGroups2 = {...dataGroups}
        const indexTable = dataGroups2.data.map(group => group.id).indexOf(id);
        const index = dataEvent2.groups.map(group => group).indexOf(id);  
        dataEvent2.groups.splice(index, 1);
        dataGroups2.data.splice(indexTable, 1);
        setDataGroups(dataGroups2);
    }

    const handleClickAddUserNoAdded = id => {
        const dataEvent2 = {...dataEvent}
        if(!dataEvent2.groups) {
            dataEvent2.groups = [];
        }
        dataEvent2.groups.push(id);
        setDataEvent(dataEvent2);

        const dataGroups2 = {...dataGroups};
        const indexTable = dataGroups2.data.map(group => group.id).indexOf(id); 
        dataGroups2.data.splice(indexTable, 1);
        setDataGroups(dataGroups2);
    }

    const loadImage = (image) => {
        setImageEvent(image);
        setImageUrlEvent(URL.createObjectURL(image));
    }
    //refactorizado
    
    const onChangeQuery = event => setQuery(event.target.value);
    
    const handleClickAddGroup= id => setDataGroups(dataGroups.map(d => ({...d, added: d.id === id ? true : d.added})));

    const handleClickDeleteGroup = id => setDataGroups(dataGroups.map(d => ({...d, added: d.id === id ? false : d.added})));

    const handleClickAddEvent = () => setDataNewEvents([...dataNewEvents, {url: '', net: 'Facebook'}]);

    const handleClickDeleteEvent = index => setDataNewEvents(dataNewEvents.filter((d, i) => i !== index));

    const onChangeUrlAdd = (value, index) => setDataNewEvents(dataNewEvents.map((d, i) => ({...d, url: index === i ? value : d.url})));

    const handleChangeNetAdd = (value, index) => setDataNewEvents(dataNewEvents.map((d, i) => ({...d, net: index === i ? value : d.net})));

    const handleChangeWithoutDateFinal = () => setWitoutDateFinal(!withoutDateFinal);

    const showUsers = async (userIds) => {
        let users = [];

        for (let i = 0; i < userIds.length; i++) {
            const user = await Services.getDocById("users", userIds[i]);
            users.push(user.data());
        }

        setUsersEvent(users);
        setOpenEventUsersDialog(true);
    }

    //#endregion
    return (
        <div className={classes.root} >
            <HeaderView
                Text={'DETALLES'}
            />
            <Container>
                <Grid container spacing={1} style={{marginTop:30}}>
                    <div style={{ width: '100%' }}>
                        <Box display="flex">
                            <Box flexGrow={1} style={{marginBottom:30}}>
                                <Button variant="contained" className={classes.btnUpdate} onClick={() => history.push('/events/list/'+ match.params.page)}>
                                    Regresar
                                </Button>
                            </Box>
                            <Box style={{marginBottom:30}}>
                            {
                                !loading
                                ?
                                    <Button variant="contained" className={classes.btnUpdate} onClick={addOrUpdate}>
                                        {match.params.id == 0 ? 'AGREGAR' : 'GUARDAR'}
                                    </Button>
                                :
                                    <CircularProgress />
                            }
                                
                            </Box>
                        </Box>
                    </div>
                    {
                        match.params.id != 0 
                        ?   <>
                            <Grid item xs={6} md={2}>
                                <Typography className={classes.fontTitles}>Nivel de actividad</Typography>
                                <ActivityLevel level={eventLevel} />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography className={classes.fontTitles}>Fecha de registro</Typography>
                                <Typography style={{marginTop:7}}>{dataEvent ? moment(Date.parse(dataEvent.registry.toDate())).format('DD-MM-YYYY HH:mm') : ''}</Typography>
                            </Grid>
                            </>
                        :
                            <>
                            <Grid item xs={12} md={2}>
                                <Button variant="contained" component="label">
                                    Imagen de evento
                                    <Input type="file" accept="image/x-png,image/gif,image/jpeg"
                                     style={{ display: "none" }} onChange={ e => loadImage(e.target.files[0])}
                                    />
                                </Button>    
                            </Grid>
                             <Grid item xs={12} md={2}>
                                { imageUrlEvent != '' ? <img src={imageUrlEvent} className={classes.imageEvent} /> : null }
                            </Grid>
                            </>    
                    }
                    <Grid item xs={12} md={4}>
                        <TextField required variant="filled" onChange={(event) => onChange("name", event.target.value)} 
                            value={dataEvent ? dataEvent.name : ''} fullWidth label="Nombre del evento" 
                            error={errors.includes("name")} helperText={'El "Nombre del evento" es requerido.'}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField required variant="filled" onChange={(event) => onChange("city", event.target.value)} 
                            value={dataEvent ? dataEvent.city : ''} fullWidth label="Ciudad" 
                            error={errors.includes("city")} helperText={'La "Ciudad" es requerida.'}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{marginTop:20}}>
                {
                    match.params.id != 0 
                    ?
                        <>
                        <Grid item xs={6} md={2}>
                            <Typography className={classes.fontTitles} >Cumplieron</Typography>
                            <Typography className={classes.fontSubTitles} style={{color: '#8bc34a'}}>{dataEvent ? dataEvent.usersWhitEventComplete.length : 0}</Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography className={classes.fontTitles}>Pendientes</Typography>
                            <Typography className={classes.fontSubTitles}>{dataEvent ? dataEvent.users.length : 0 - (dataEvent ? dataEvent.usersWhitEventComplete.length : 0)}</Typography>
                        </Grid>
                        </>
                    :
                        <Grid item xs={12} md={4}></Grid>

                }
                <Grid item xs={12} md={8}>
                    <TextField required variant="filled" onChange={(event) => onChange("description", event.target.value)} 
                        value={dataEvent ? dataEvent.description : ''} multiline rows="2" 
                        fullWidth label="Descripción"
                        error={errors.includes("description")} helperText={'La "Descripción" es requerida.'}
                    />
                </Grid>
                </Grid>
                {
                    match.params.id == 0
                    ?
                        <div style={{ paddingTop: 30, height: dataNewEvents.length <= 1 ? 90 : 180, overflowY: 'auto', overflowX:'hidden'}}>
                        {dataNewEvents.map((event, index) => (
                            <Grid container spacing={1} key={index}>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={1}>
                                {
                                    index == 0 
                                    ? 
                                        <IconButton style={{marginTop:10}} onClick={handleClickAddEvent} >
                                            <AddIcon style={{color: '#bf1e2e'}}/>
                                        </IconButton>
                                    :
                                        null
                                }
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <TextField required variant="filled" value={event.url} onChange={(e) => onChangeUrlAdd(e.target.value, index)} 
                                        fullWidth label="URL" 
                                        error={errors.includes("urls") && !event.url} helperText={'La "Url" es requerida.'}
                                    />
                                </Grid>
                                <Grid item xs={5} md={3}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel >Red social</InputLabel>
                                        <Select
                                            value={event.net}
                                            onChange={(e) => handleChangeNetAdd(e.target.value, index)}
                                        >
                                            <MenuItem value="Facebook">Facebook</MenuItem>
                                            <MenuItem value="Twitter">Twitter</MenuItem>
                                            <MenuItem value="Instagram">Instagram</MenuItem>
                                            <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                                            <MenuItem value="YouTube">YouTube</MenuItem>
                                            <MenuItem value="TikTok">TikTok</MenuItem>
                                            <MenuItem value="Internet">Otro<LanguageIcon /></MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    index !== 0 
                                    ?
                                        <Grid item xs={1} md={1}>
                                            <IconButton style={{marginTop:10}}  color="primary" onClick={() => {handleClickDeleteEvent(index)}} component="span"> 
                                                <DeleteIcon style={{color: '#bf1e2e'}}/> 
                                            </IconButton>
                                        </Grid>
                                    :
                                        null
                                }
                               
                            </Grid>
                        ))}
                        </div>
                    :
                        <Grid container spacing={1} style={{marginTop:30}}>
                            <Grid item xs={6} md={2}>
                            { 
                                <img src={imageUrlEvent ? imageUrlEvent : sinImagen} className={classes.imageEvent} /> 
                            }
                            </Grid>
                            <Grid item xs={6} md={2}>
                            { 
                                <Button variant="contained" component="label">
                                    Imagen de evento
                                    <Input type="file" accept="image/x-png,image/gif,image/jpeg"
                                        style={{display: "none"}} onChange={e => loadImage(e.target.files[0])}
                                    />
                                </Button>    
                            }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField required variant="filled" onChange={(event) => onChange("url", event.target.value)} 
                                    value={dataEvent ? dataEvent.url : ''} fullWidth label="URL" 
                                    error={errors.errorUrl} helperText={'La "Url" es requerida.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl variant="filled" fullWidth>
                                <InputLabel >Red social</InputLabel>
                                <Select
                                    value={dataEvent ? dataEvent.net : ""}
                                    onChange={(event) => onChange("net", event.target.value)}
                                >
                                    <MenuItem value="Facebook">Facebook</MenuItem>
                                    <MenuItem value="Twitter">Twitter</MenuItem>
                                    <MenuItem value="Instagram">Instagram</MenuItem>
                                    <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                                    <MenuItem value="YouTube">YouTube</MenuItem>
                                    <MenuItem value="TikTok">TikTok</MenuItem>
                                    <MenuItem value="Internet">Otro<LanguageIcon /></MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                        </Grid>
                }
                <Grid container spacing={1} style={{marginTop:30}}>
                    <Grid item xs={6} md={3}>
                        <TextField label="Fecha de inicio" onChange={(event) => onChange("dateInitial", event.target.value)} value={dataEvent ? dataEvent.dateInitial : ""} 
                            variant="filled"  fullWidth type="datetime-local" 
                            InputLabelProps={{ 
                                shrink: true
                            }} 
                            InputProps={{ 
                                inputProps: { 
                                    min: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm') 
                                } 
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField disabled={withoutDateFinal} label="Fecha de finalización" onChange={(event) => onChange("dateFinal", event.target.value)} 
                            value={dataEvent ? dataEvent.dateFinal: ""} variant="filled" fullWidth type="datetime-local" 
                            InputLabelProps={{ 
                                shrink: true
                            }} 
                            InputProps={{ 
                                inputProps: { 
                                    min: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm') 
                                }
                            }}
                            error={errors.errorDateFinal && !withoutDateFinal} 
                            helperText={match.params.id != 0 && !withoutDateFinal ? 'La "Fecha de finalización es requerida.' : ''}
                        />
                    </Grid>
                    <Grid item xs={6} md={3} >
                        <Typography align='center'>Sin fecha de finalización</Typography>
                        <Box display="flex" justifyContent="center">
                            <Checkbox value={withoutDateFinal} onChange={handleChangeWithoutDateFinal} ></Checkbox>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth variant="filled" className={classes.formControl}>
                            <InputLabel>Acción</InputLabel>
                            <Select
                                multiple
                                value={dataEvent && dataEvent.actions ? dataEvent.actions : []}
                                onChange={(event) => onChange("actions", event.target.value)}
                                input={<Input />}
                                MenuProps={MenuProps}
                            >
                            {actionsNames.map(name => (
                                <MenuItem key={name} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <br/><br/><br/>
                <Divider />
                <Grid container spacing={2} style={{marginTop:10}}>
                    <Grid item xs={12} md={7} >
                        <Typography className={classes.fontSubTitles} style={{color: '#757575'}}>{match.params.id == 0 ? 'AGREGAR GRUPOS' : modeAdd ? 'AGREGAR GRUPOS' : 'GRUPOS ASIGNADOS'} </Typography>
                    </Grid>
                    <Grid item xs={7} md={3}>
                            <TextField  color="secondary" placeholder="Buscar grupos" fullWidth 
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={onChangeQuery}
                            />
                    </Grid>
                    <Grid item xs={5} md={2}>
                    { match.params.id != 0 ? <Button variant="contained" onClick={handleClickGetGroupsNoAddedOrAdded} fullWidth className={classes.btnUpdate}>{ modeAdd ? 'GRUPOS ASIGNADOS' : 'AGREGAR GRUPOS'}</Button> : null } 
                    </Grid>
                </Grid>
                <br/>
                <Card className={classes.card}>
                    <div style={{overflowX: 'auto'}}>   
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell >Usuarios en grupo</TableCell>
                                    <TableCell >Registro</TableCell>
                                    <TableCell align='center'>Acción</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {dataGroups.filter(group => group.name
                                    .toLowerCase()
                                    .includes(query))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(row => (
                                <TableRow key={row.id} style={{backgroundColor: match.params.id == 0 && row.added ? "lightgray" : "white"}}>
                                    <TableCell component="th" scope="row"> {row.name} </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Grid container>
                                            <Grid item md={3} style={{marginTop: 2}}>
                                                {row.users ? row.users.length : 0}
                                            </Grid>
                                            <Grid item md={6}>
                                                <VisibilityIcon style={{cursor: "pointer"}} onClick={async () => await showUsers(row.users)} />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell component="th" scope="row"> {moment(Date.parse(row.registry.toDate())).format('DD-MM-YYYY HH:mm')} </TableCell>
                                    <TableCell align='center' component="th" scope="row"> 
                                            { 
                                                match.params.id == 0 
                                                ?
                                                    row.added
                                                    ?
                                                        <IconButton color="primary" aria-label="upload picture" onClick={() => {handleClickDeleteGroup(row.id)}} component="span"> 
                                                            <DeleteIcon /> 
                                                        </IconButton>
                                                    
                                                    :
                                                        <IconButton color="primary" aria-label="upload picture" onClick={() => {handleClickAddGroup(row.id)}} component="span"> 
                                                            <AddIcon /> 
                                                        </IconButton>
                                                    
                                                :   
                                                    
                                                    !modeAdd
                                                    ?
                                                        <IconButton color="primary" aria-label="upload picture" onClick={() => {handleClickDeleteGroupAdded(row.id)}} component="span"> 
                                                            <DeleteIcon /> 
                                                        </IconButton>
                                                    :
                                                        <IconButton color="primary" aria-label="upload picture" onClick={() => {handleClickAddUserNoAdded(row.id)}} component="span"> 
                                                            <AddIcon /> 
                                                        </IconButton>
                                            }
                                            </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        component="div"
                        count={dataGroups.filter(group => group.name
                            .toLowerCase()
                            .includes(query))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={(e, page) => setPage(page)}
                        onChangeRowsPerPage={e => {setRowsPerPage(e.target.value); setPage(0);}}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count}`}
                        labelRowsPerPage={'Renglones por página'}
                        /> 
                </Card>
            </Container>
            <Alert
                open={Boolean(error)}
                onClose={() => setError(null)}
                message={error}
                severity="error"
            />
            <EventUsersDialog 
                users={usersEvent}
                onAccept={() => setOpenEventUsersDialog(false)}
                open={openEventUsersDialog}
            />
        </div>
    );
}

export default FormEventsView;