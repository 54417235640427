import React from 'react';
import { Grid } from '@material-ui/core';
import UserDetail from './UserDetail';
import UserPicSelection from './UserPicSelection';
import ActivityLevel from './../../events/EventsView/ActivityLevel';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  }
}));

const UserDetails = ({ user, userEventsData, userLevel }) => {
  const classes = useStyles();
  let level, registry;
  if (user) {
    level = userLevel;
    registry = user.registry ? user.registry : 'Sin fecha de registro';
  }
  level = level || 0;

  let eventsCompleteCount, eventsPendingCount, eventsIncompleteCount;

  if(userEventsData) {
    eventsCompleteCount = userEventsData.eventsCompleteCount;
    eventsPendingCount = userEventsData.eventsPendingCount;
    eventsIncompleteCount = userEventsData.eventsIncompleteCount;
  }
  
  return (
    <Grid item xs={4}>
      <Grid 
        spacing={2}
        className={classes.root}
        container justify="space-between">
        <UserPicSelection
          user={user}/>
        <UserDetail
          fullWidth
          label="Nivel de actividad"
          detail={<ActivityLevel level={level}/>}
          />
        {
          user?
            <>
              <UserDetail
                bigText
                label="Atendidos"
                detail={eventsCompleteCount}
                color="#6d9440"/>
              <UserDetail
                bigText
                label="Pendientes"
                detail={eventsPendingCount}
                color="#000000"/>
              <UserDetail 
                bigText
                label="Incompletos" 
                detail={eventsIncompleteCount} 
                color="#d23030"/>
              <UserDetail
                label="Proveedor"
                detail={'Facebook'}/>
              <UserDetail 
                label="Fecha de Registro" 
                detail={moment(Date.parse(registry.toDate())).format('DD-MM-YYYY HH:mm')}/>
            </>
            : null
        }
      </Grid>
    </Grid>
  );
};

export default UserDetails;