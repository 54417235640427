import React from 'react';
import { 
  Button,
  Dialog, DialogActions,
  DialogContent, Grid,
  DialogTitle, Typography, Divider
} from '@material-ui/core';

const EventsDialog = ({ open, onAccept, groupEventActives, groupEventInactives, groupsEventsCount }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description">
      <DialogTitle id="delete-dialog-title">Eventos: {groupsEventsCount}</DialogTitle>
      <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                { 
                    groupEventActives && groupEventActives.length > 0
                    ?
                    <>
                    <DialogTitle align='center'>Eventos activos</DialogTitle> 
                    <div style={{ overflowY: 'auto', overflowX:'hidden'}}>
                        {
                        groupEventActives.map((event) => (
                            <div  style={{margin:10}} key={event.id}>
                                <Typography>{event.name}</Typography>
                                <Divider />
                            </div>
                        ))
                        }
                    </div>
                    </>
                    :
                    <DialogTitle>Sin eventos activos</DialogTitle> 
                }
                </Grid>
                <Grid item xs={12} md={6}>
                { 
                    groupEventInactives && groupEventInactives.length > 0
                    ?
                    <>
                    <DialogTitle align='center'>Eventos inactivos</DialogTitle> 
                    <div style={{ overflowY: 'auto', overflowX:'hidden'}}>
                    {
                        groupEventInactives.map((event) => (
                            <div  style={{margin:10}} key={event.id}>
                                <Typography>{event.name}</Typography>
                                <Divider />
                            </div>
                        ))
                    }
                    </div>
                    </>
                    :
                    <DialogTitle>Sin eventos inactivos</DialogTitle> 
                }
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onAccept} color="primary" autoFocus>
                Aceptar
            </Button>
        </DialogActions>
  </Dialog>
  );
};

export default EventsDialog;