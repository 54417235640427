import React, { useState } from 'react';
import Container from './../../../components/Container';
import Table from './../../../components/Table/index';
import ViewHeader from './../../../components/HeaderView';
import TableIconButton from './../../../components/TableIconButton';
import DeleteDialog from './../../../components/DeleteDialog';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import Loader from './../../../components/Loader';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import EventsDialog from './EventsDialog';
import Services  from '../../../services';
import UsersGroupDialog from './UsersGroupDialogView';

const useStyles = makeStyles(theme => ({
  actions: {
    '& > *': {
      margin: theme.spacing(0,0.7)
    }
  }
}));

const header = [
  {
    prop: 'name',
    name: 'Nombre'  
  }
];

const GroupsView = ({match, history }) => {
  const classes = useStyles();
  useFirestoreConnect([
    {
      collection: 'groups',
      orderBy: ["registry", "desc"]
    }, 
  ]);
  const groups = useSelector(state => state.firestore.ordered.groups);
  const [ idGroupToDelete, setIdGroupToDelete ] = useState(null);
  const [ groupEventActives, setGroupEventActives] = useState([]);
  const [ groupEventInactives, setGroupEventInactives] = useState([]);
  const [ openEventsDialog, setOpenEventsDialog] = useState(false);
  const [ page, setPage] = useState(parseInt(match.params.page) || 0);
  const [ groupsEventsCount, setGroupsEventsCount ] = useState(0);
  const [ openUsersDialog, setOpenUsersDialog ] = useState(false);
  const [ usersGroup, setUsersGroup] = useState([]);

  if (!isLoaded(groups)) {
    return <Loader />;
  }

  const handleEdit = id => {
    history.push(`/groups/edit/${ id }/${ page }`);
  }

  const handleAdd = () => {
    history.push(`/groups/create/${ page }`);
  }

  const handleDeleteSelect = id => {
    setIdGroupToDelete(id);
  }

  const handleDelete = async () => {
    try {
      await Services.delete("groups", idGroupToDelete);
    } catch (error) {
      console.log(error);
    } finally {
      setIdGroupToDelete(null);
    }
  }

  const showEvents = async eventIds => {
    let groupEvents = [];
    let _groupEventActives = [];
    let _groupEventInactives = [];

    for (let i = 0; i < eventIds.length; i++) {
      const event = await Services.getDocById("events", eventIds[i]);
     
      groupEvents.push({
        ...event.data(),
        id: event.id
      });
    }

    const groupEventsOrdered = groupEvents.sort((a, b) => (a.name && a.name > b.name) ? 1 : -1);

    if(groupEventsOrdered.length > 0) {
      groupEvents.forEach(groupEvent => {
        if(!groupEvent.dateFinal ||  groupEvent.dateFinal >= moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')) {
          _groupEventActives.push(groupEvent);
        }
        else {
          _groupEventInactives.push(groupEvent);
        }
      });
    }

    setGroupsEventsCount(groupEvents.length);
    setGroupEventActives(_groupEventActives);
    setGroupEventInactives(_groupEventInactives);
    setOpenEventsDialog(true);
  }

  const showUsers = async (userIds) => {
    let users = [];

    for (let i = 0; i < userIds.length; i++) {
        const user = await Services.getDocById("users", userIds[i]);
        users.push(user.data());
    }

    setUsersGroup(users);
    setOpenUsersDialog(true);
}

  return (
    <> 
      <ViewHeader
        rowsCount={groups.length}
        Text="Grupos"/>
      <Container>
      <Grid container justify="flex-end">
          <Button
            style={{backgroundColor: '#bf1e2e', color: 'white'}}
            onClick={handleAdd}
            variant="contained"
           >
            Nuevo Grupo
          </Button>
        </Grid>
        <br/>
        <Table
          filter={['name']}
          paginated
          header={header}
          data={groups}
          extraRows={[
            {
              prop: 'users',
              name: 'Usuarios del grupo',
              cell: row => <Grid container>
                <Grid item md={3} style={{marginTop: 2}}>
                  { row.users && row.users.length || 0 }
                </Grid>
                <Grid item md={6}>
                  <Visibility style={{cursor: "pointer"}} onClick={async () => await showUsers(row.users)} />
                </Grid>
              </Grid>
            },
            {
              prop: 'registry',
              name: 'Fecha de registro',
              cell: row => (
                <>{moment(Date.parse(row.registry.toDate())).format('DD-MM-YYYY HH:mm')}</> 
              )
            }, 
            {
              prop: 'active-events',
              name: 'Eventos',
              cell: row => (
                <TableIconButton
                  onClick={async () => await showEvents(row.eventIds)}
                  icon={Visibility}/>
              )
            },
            {
              prop: 'actions',
              name: 'Modificar',
              cell: row => (
                <span className={classes.actions}>
                  <TableIconButton
                    onClick={() => handleEdit(row.id)}
                    icon={Edit}/>
                  <TableIconButton
                    onClick={() => handleDeleteSelect(row.id)}
                    icon={Delete}/>
                </span>
              )
            }
          ]}
          _page={page}
          onChangePage={page => setPage(page)}
        />
      </Container>
      <DeleteDialog
        open={Boolean(idGroupToDelete)}
        onClose={() => setIdGroupToDelete(null)}
        onCancel={() => setIdGroupToDelete(null)}
        onAccept={async () => await handleDelete()}
      />
      <EventsDialog
        open={openEventsDialog}
        onAccept={() => setOpenEventsDialog(false)}
        groupEventActives={groupEventActives}
        groupEventInactives={groupEventInactives}
        groupsEventsCount={groupsEventsCount}
      />
      <UsersGroupDialog 
        users={usersGroup}
        onAccept={() => setOpenUsersDialog(false)}
        open={openUsersDialog}
      />
    </>
  );
};

export default GroupsView;