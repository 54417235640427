import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Divider } from '@material-ui/core';

const EventUsersDialog = ({ open, onAccept, users }) => {
  return (
    <Dialog
      open={open}
     >
      <DialogTitle >Usuarios del grupo</DialogTitle>
      <DialogContent>
      { 
        users && users.length > 0
        ?
          <div style={{ overflowY: 'auto', overflowX:'hidden'}}>
            {
              users && users.map((user, index) => (
                  <div  style={{margin:10}} key={index}>
                      <Typography>{user.email ? user.email : user.name}</Typography>
                      <Divider />
                  </div>
              ))
            }
          </div>
        :
        <Typography>Sin usuarios asignados</Typography> 
      }
      </DialogContent>
      <DialogActions>
          <Button onClick={onAccept} color="primary" autoFocus>
              Aceptar
          </Button>
      </DialogActions>
  </Dialog>
  );
};

export default EventUsersDialog;