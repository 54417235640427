import React from 'react';
import 'typeface-roboto';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MeetingRoom } from '@material-ui/icons';
import { Grid, Box } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import logo from './../../white-logo.png';
import UserDetail from './UserDetail';
import { withRouter } from 'react-router';
import MobileHeader from './MobileDrawer';
import {
  Home, Sms, Description,
  Group, Games, Inbox
} from '@material-ui/icons';

const links = [
  { to: '/', text: 'Inicio', icon: Home }, 
  { to: '/users/list/0', text: 'Usuarios', icon: Sms }, 
  { to: '/groups/list/0', text: 'Grupos', icon: Group }, 
  { to: '/events/list/0', text: 'Eventos', icon: Games },
  { to: '/reports', text: 'Reportes', icon: Inbox },
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textTransform: 'uppercase',
    backgroundColor: '#fafafa',
    position: 'relative',
    zIndex: '999',
    boxShadow: '-1px -3px 18px 6px rgba(0,0,0,0.12)',
    height: '80px',
    '& > *': {
      height: '100%'
    },
    '& img': {
      width: '150px'
    }
  },
  mobileButton: {
    display: 'flex'
  },
  link: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    textDecoration: 'none',
    fontFamily: 'roboto',
    color: '#575555',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.07)'
    }
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.07)'
  },
  icon: {
    color: '#575555'
  }
}));

const getNavLinks = (links, classes) => {
  return links.map(link => {
    return (
      <NavLink
        exact={Boolean(link.to === '/')}
        key={link.to}
        to={link.to}
        activeClassName={classes.active}
        className={classes.link}>
        { link.text }
      </NavLink>
    )}
  )
}

const Header = ({ location, hideHeaderLinks }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} /* px={4}  */> 
      <Grid container style={{height: 80}}>
        <Grid xs={1}
          className={classes.mobileButton}
          item
          container
          style={{height: 80}}

          justify="center">
            <MobileHeader links={links}/>
        </Grid>
        <Grid item xs={2}>
          <Grid
            container style={{height: 80}}
            container 
            direction="row" 
            justify="flex-start" 
            alignItems="center">
            <Link to="/">
              <img src={logo} height={76} style={{objectFit: "contain"}}/>
            </Link>
          </Grid>
        </Grid>
        <Grid
          container style={{height: 80}}
          xs={7}
          item 
          container 
          direction="row" 
          justify="flex-start" 
          alignItems="center">
          { 
            hideHeaderLinks?
              null 
              : getNavLinks(links, classes) 
          }
        </Grid>
        <Grid xs={2}
          className={classes.userOptions}
          item >
          <Grid 
            container 
            container style={{height: 80}}
            direction="row" 
            justify="flex-end" 
            alignItems="center">
            <UserDetail 
              active={location && location.pathname.includes('profile') 
                      || location.pathname.includes('administracion')}
            />
            <Button>
              <Link to="/logout">
                <MeetingRoom className={classes.icon}/>
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(Header);